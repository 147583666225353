<template>
  <div class="d-flex flex-column">
    <!-- {{componentName(item)}} : {{iconName(item)}} : {{item}} -->
    <!-- {{viewType}} -->
    <component
      :is="componentName(item)"
      :id="item.id"
      :cache-type="$cacheTypeFromString(item.type)"
      :color="color"
      :icon-name="iconName(item)"
      :show-icon="_showIcon"
      :icon-props="iconProps"
      :dense="dense"
      :category-props="categoryProps"
      :hide-fullname="hideFullname"
      :hide-email="hideEmail"
      :item="item"
      :options="{showDetail: showDetail}"
      :hide-group="hideGroup"
      :hide-phone="hidePhone"
      :avatarProps="avatarProps"
      :offset-y="offsetY"
      :mixed="mixed"
      :view-type="viewType"
      :can-edit-column="canEditColumn"
      :emphasis="emphasis"
      :is-for-selection="isForSelection"
      ref="document"
      :block-popup="isForSelection"
      :icon-mark-props="iconMarkProps"
    >
      <template
        v-slot:doc-type
        v-if="showDetail"
      >
        <span class=" nowrap text-truncate max-width-text doc-type">{{$t(`t.${toPascalCase(item.type)}`)}}</span>
      </template>
    </component>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    AllocationAnnouncementRef: () => import('@/components/documents-ref/allocation-announcement-ref'),
    BusinessDivisionRef: () => import('@/components/documents-ref/business-division-ref'),
    CalendarBasedRemindersRef: () => import('@/components/documents-ref/calendar-based-reminders-ref'),
    CameleonFieldRef: () => import('@/components/documents-ref/cameleon-field-ref'),
    CollaborationTaskRef: () => import('@/components/documents-ref/collaboration-task-ref'),
    ColumnRef: () => import('@/components/documents-ref/column-ref'),
    ColumnSetRef: () => import('@/components/documents-ref/column-set-ref'),
    CultureRef: () => import('@/components/documents-ref/culture-ref'),
    DisputeReasonRef: () => import('@/components/documents-ref/dispute-reason-ref'),
    DisputeRef: () => import('@/components/documents-ref/dispute-ref'),
    DisputeInvoiceRef: () => import('@/components/documents-ref/dispute-invoice-ref'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref'),
    DocumentNumberRef: () => import('@/components/documents-ref/document-number-ref'),
    DunningRemindersRef: () => import('@/components/documents-ref/dunning-reminders-ref'),
    GroupamaRequestRef: () => import('@/components/documents-ref/groupama-request-ref'),
    InvoiceRef: () => import('@/components/documents-ref/invoice-ref'),
    LabelRef: () => import('@/components/documents-ref/label-ref'),
    MessageRef: () => import('@/components/documents-ref/message-ref'),
    PostRef: () => import('@/components/documents-ref/post-ref'),
    PromiseInstallmentRef: () => import('@/components/documents-ref/promise-installment-ref'),
    PromiseRef: () => import('@/components/documents-ref/promise-ref'),
    PromiseInvoiceRef: () => import('@/components/documents-ref/promise-invoice-ref'),
    ReminderTypeRef: () => import('@/components/documents-ref/reminder-type-ref'),
    RunRef: () => import('@/components/documents-ref/run-ref'),
    SortSetRef: () => import('@/components/documents-ref/sort-set-ref'),
    TopicRef: () => import('@/components/documents-ref/topic-ref'),
    UnappliedCashesRef: () => import('@/components/documents-ref/unapplied-cashes-ref'),
    UserRef: () => import('@/components/documents-ref/user-ref'),
    WorkItemRef: () => import('@/components/documents-ref/work-item-ref'),
    WorkItemTemplateRef: () => import('@/components/documents-ref/work-item-template-ref'),
    WorkQueueRef: () => import('@/components/documents-ref/work-queue-ref')

  },
  data: function () {
    return {
      innerItem: null
    }
  },
  computed: {
    _showIcon () {
      return this.showIcon && this.iconName(this.item) !== 'i.AutoFilters'
    },
    isForSelection () {
      return this.userRefProps?.isForSelection
    },
    hideFullname () {
      return this.userRefProps?.hideFullname
    },
    hideEmail () {
      return this.userRefProps?.hideEmail
    },
    hideGroup () {
      return this.userRefProps?.hideGroup
    },
    hidePhone () {
      return this.userRefProps?.hidePhone
    },
    avatarProps () {
      return this.userRefProps?.avatarProps
    },
    offsetY () {
      return this.userRefProps?.offsetY
    }
  },
  methods: {
    iconName (item) {
      if (!item.type) { return }

      return item.type.includes('status') ? 'i.Status' : `i.${this.toPascalCase(item.type)}`
    },
    componentName (item) {
      switch (item.type) {
        case 'account-contacts':
        case 'users':
          return 'user-ref'

        case 'accounts':
          return 'account-ref'
        case 'allocation-announcements':
          return 'allocation-announcement-ref'
        case 'business-divisions':
          return 'business-division-ref'
        case 'calendar-based-reminders':
          return this.dense ? 'document-number-ref' : 'calendar-based-reminders-ref'
        case 'columns':
          return 'column-ref'
        case 'column-sets':
          return 'column-set-ref'
        case 'collaboration-tasks':
          return this.dense ? 'document-number-ref' : 'collaboration-task-ref'
        case 'disputes':
          return this.dense ? 'document-number-ref' : 'dispute-ref'
        case 'invoices':
          return this.dense ? 'document-number-ref' : 'invoice-ref'
        case 'promises':
          return this.dense ? 'document-number-ref' : 'promise-ref'
        case 'promise-installments':
          return 'promise-installment-ref'
        case 'dispute-reasons':
          return 'dispute-reason-ref'

        case 'dunning-reminders':
          return this.dense ? 'document-number-ref' : 'dunning-reminders-ref'
        case 'groupama-requests':
          return 'groupama-request-ref'
        case 'labels':
          return 'label-ref'

        case 'messages-outbox':
        case 'messages-inbox':
        case 'messages-unassigned':
          return 'message-ref'
        case 'posts':
          return 'post-ref'
        case 'sort-sets':
          return 'sort-set-ref'
        case 'topics':
          return 'topic-ref'

        case 'work-item-templates':
          return 'work-item-template-ref'

        case 'work-items':
          return 'work-item-ref'

        case 'work-queues':
          return 'work-queue-ref'
        case 'fields':
          return 'cameleon-field-ref'
        case 'reminder-types':
          return 'reminder-type-ref'

        case 'aging-bucket-range-ref':
        case 'announcement-origins':
        case 'announcement-statuses':
        case 'aging-scenarios':
        case 'ar24-message-statuses':
        case 'ar24-messages':
        case 'auto-filters':
        case 'calendar-based-reminder-protocols':
        case 'calendar-based-reminder-statuses':
        case 'collaboration-task-types':
        case 'collection-segments':
        case 'contact-groups':
        case 'credit-hold-reasons':
        case 'credit-statuses':
        case 'credit-status-groupamas':
        case 'currencies':
        case 'dispute-reason-categories':
        case 'document-types':
        case 'dunning-reminder-statuses':
        case 'escalation-protocol-levels':
        case 'escalation-protocols':
        case 'group-contributors':
        case 'groups':
        case 'groupama-decision-codes':
        case 'import-data-sources':
        case 'new-import':
        case 'invoice-statuses':
        case 'label-groups':
        case 'maileva-letters':
        case 'maileva-letter-statuses':
        case 'main-presets':
        case 'message-priorities':
        case 'portal-payment-statuses':
        case 'portal-statuses':
        case 'processes':
        case 'promise-statuses':
        case 'providers':
        case 'provider-types':
        case 'relative-currencies':
        case 'reminder-status':
        case 'risk-categories':
        case 'roles':
        case 'run-statuses':
        case 'run-types':
        case 'status-disputes':
        case 'templates':
        case 'transaction-types':
        case 'work-item-priorities':
        case 'work-item-types':
        case 'workflows':
          return 'document-name-ref'
        case 'unapplied-cashes':
          return 'unapplied-cashes-ref'
        case 'dunning-runs':
        case 'reminder-runs':
          return 'run-ref'
        case 'cultures':
          return 'culture-ref'
        default:
          console.trace(`document-picker-list-item-ref missing componentName ${item.type}`)
          return null
      }
    },
    cacheType (type) {
      this.$cacheTypeFromString(type)
    }
  },
  mounted () {

  },
  watch: {

  },
  props: {
    canEditColumn: Boolean,
    item: {
      type: Object,
      required: true
    },
    categoryProps: Object,
    color: String,
    dense: Boolean,
    emphasis: Boolean,
    iconProps: Object,
    mainAgentProps: Object,
    showDetail: Boolean,
    showIcon: Boolean,
    mixed: {
      type: Boolean,
      default: false
    },
    userRefProps: Object,
    viewType: String,
    iconMarkProps: Object
  }
}
</script>

<style lang="stylus" scoped>
.doc-type
  font-size 12px
  opacity 0.6
</style>
